class HeroShape extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: "open" });
  }
  connectedCallback() {
    const src = this.getAttribute("src");
    const hasAnimation = this.hasAttribute("withAnimation");
    const path = "M311.889 300C264.842 296.581 219.301 274.316 187.527 234.572L0 0L449.152 0L461.602 15.5736C522.092 91.2383 509.775 201.601 434.091 262.076C405.341 285.048 371.584 297.517 337.456 300H311.889Z";
    const animations = hasAnimation ? `<animateTransform attributeName="transform" type="scale" from="0 0" to="1 1" dur="0.5s" begin="0s" fill="freeze" calcMode="spline" keySplines="0 1 1 1" additive="sum" /><animateTransform attributeName="transform" type="translate" from="-200 -300" to="0 0" dur="0.5s" begin="0s" fill="freeze" calcMode="spline" keySplines="0 1 1 1" additive="sum" />` : "";
    const svg = `<svg width="100%" height="auto" viewBox="0 0 500 300" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg"><defs><mask id="heroMask" maskUnits="userSpaceOnUse"><path fill="white" d="${path}">${animations}</path></mask></defs><image xlink:href="${src}" width="500" height="300" x="0" y="0" mask="url(#heroMask)" preserveAspectRatio="xMidYMid slice" /></svg>`;
    const svgUrl = `data:image/svg+xml,${encodeURIComponent(`<svg viewBox="0 0 500 300" xmlns="http://www.w3.org/2000/svg"><path d="${path}"/></svg>`)}`;
    this.style.shapeOutside = `url("${svgUrl}")`;
    this.shadowRoot.innerHTML = `<style>:host { display: block; width: 100%; }</style>${svg}`;
  }
}
customElements.define("hero-shape", HeroShape);
