/*
 * Header Notification
 * example:

<header-notification
  notification-id="nieuw-bij-cogo"
  notification-type="info"
  image-src="/cogoecomwcs/static/efood/img/notice-bulb.svg"
  notification-conditional="only-loggedout-and-new-customers"
>

Nieuw bij Collect&Go? Gebruik promotiecode <span class="tk-mic-32-new-rounded-web" style="color: white; background-color: #e62d38; padding: 0 6px; border-radius: 25px; white-space: nowrap; font-style: italic">WELKOM253</span> en je eerste servicekost valt weg. <a href="/colruyt/nl/online-reserveren-in-vijf-stappen" data-tms-intpromo-name="acquisitie_message_link">Zo werkt het!</a>

</header-notification>

 */

class HeaderNotification extends HTMLElement {
  static notificationQueue = [];
  static observerStarted = false;

  constructor() {
    super();
    this.notificationId = this.getAttribute("notification-id");
    this.notificationType = this.getAttribute("notification-type") || "info";
    this.cookieName = `header-notification-${this.notificationId}`;
    this.notificationConditional = this.getAttribute(
      "notification-conditional"
    );
  }

  connectedCallback() {
    if (this.notificationConditional === "only-loggedout-and-new-customers") {
      if (WCParamJS.loggedIn === true && WCParamJS.userSegments !== "[]") {
        this.remove();
        return;
      }
    }

    if (
      !this.notificationId ||
      document.cookie.includes(`${this.cookieName}=hidden`)
    ) {
      return;
    }

    const notification = this.createNotificationElement();
    HeaderNotification.enqueueNotification(notification);
    this.remove();
  }

  createNotificationElement() {
    const notification = document.createElement("div");
    notification.className = "header-notification";
    notification.dataset.notificationType = this.notificationType;
    notification.dataset.notificationId = this.notificationId;

    const content = document.createElement("div");
    content.className = "header-notification-content";
    content.innerHTML = this.innerHTML;

    const wrapper = document.createElement("div");
    wrapper.className = "header-notification-wrapper";
    wrapper.appendChild(content);

    const closeButton = document.createElement("button");
    closeButton.textContent = "Sluit";
    closeButton.className = "header-notification-close";
    closeButton.addEventListener("click", () => {
      document.cookie = `${this.cookieName}=hidden; expires=${new Date(
        Date.now() + 30 * 864e5
      ).toUTCString()}; path=/`;
      notification.remove();
    });

    wrapper.appendChild(closeButton);
    notification.appendChild(wrapper);
    return notification;
  }

  static enqueueNotification(notificationEl) {
    const targetContainer = document.querySelector(".main-content-wrapper");

    if (targetContainer) {
      HeaderNotification.appendToWrapper(targetContainer, notificationEl);
    } else {
      HeaderNotification.notificationQueue.push(notificationEl);
      HeaderNotification.startObserver();
    }
  }

  static startObserver() {
    if (HeaderNotification.observerStarted) return;

    HeaderNotification.observerStarted = true;

    const observer = new MutationObserver((_, obs) => {
      const container = document.querySelector(".main-content-wrapper");
      if (container) {
        HeaderNotification.notificationQueue.forEach((n) =>
          HeaderNotification.appendToWrapper(container, n)
        );
        HeaderNotification.notificationQueue = [];
        obs.disconnect();
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });
  }

  static appendToWrapper(container, notificationEl) {
    let wrapper = container.querySelector(".header-notifications");
    if (!wrapper) {
      wrapper = document.createElement("div");
      wrapper.className = "header-notifications";
      container.prepend(wrapper);
    }
    wrapper.appendChild(notificationEl);
  }
}

document.addEventListener("DOMContentLoaded", () => {
  customElements.define("header-notification", HeaderNotification);
});
