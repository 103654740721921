class VirtualBundle extends HTMLElement {
  constructor() {
    super();
    this.config = {};
    this.products = [];
  }

  connectedCallback() {
    const title = this.getAttribute("title") || "";
    const logoSrc = this.getAttribute("logo-src") || "";
    const logoAlt = this.getAttribute("logo-alt") || "";
    const background = this.getAttribute("background") || "";
    const analyticsName = this.getAttribute("analytics-name") || "";
    const specificatie = [];
    Array.from(this.attributes).forEach((attr) => {
      if (attr.name.startsWith("product-")) {
        const parts = attr.value.split(":");
        if (parts.length === 2) specificatie.push([parts[0], Number(parts[1])]);
      }
    });
    this.config = { analyticsName, specificatie, title, logoSrc, logoAlt, background };
    const contentWidget = this.closest('[id^="contentRecommendationWidget"]');
    if (!contentWidget) return;
    let catalogWidget = null;
    if (contentWidget.parentElement) catalogWidget = contentWidget.parentElement.querySelector('[id^="catalogEntryRecommendationWidget"]');
    if (!catalogWidget) return;
    const wrapper = document.createElement("div");
    wrapper.classList.add("webbie-virtuele-bundel");
    wrapper.setAttribute("data-tms-intpromo-name", analyticsName);

    contentWidget.parentElement.insertBefore(wrapper, contentWidget);
    wrapper.appendChild(contentWidget);
    wrapper.appendChild(catalogWidget);

    const leftEspot = contentWidget.querySelector(".left_espot");
    if (leftEspot) {
      // leftEspot.insertBefore(this, leftEspot.firstChild);
      const h2 = document.createElement("h2");
      h2.textContent = title;
      const img = document.createElement("img");
      img.classList.add("webbie-virtuele-bundel--logo");
      img.src = logoSrc;
      img.alt = logoAlt;
      const styleTag = document.createElement("style");
      styleTag.textContent = `.webbie-virtuele-bundel::after { background-image: url("${background}"); }`;
      leftEspot.appendChild(h2);
      leftEspot.appendChild(img);
      leftEspot.appendChild(styleTag);
    }

    this.removeSlick(catalogWidget);
    this.products = this.transformCatalogWidget(catalogWidget);

    const buttonsDiv = document.createElement("div");
    buttonsDiv.classList.add("webbie-virtuele-bundel--buttons");
    const lang = window.WCParamJS && window.WCParamJS.langId === "-1001" ? "nl" : "fr";
    const buyButton = document.createElement("button");
    buyButton.classList.add("o-button", "--primary", "--buy");
    buyButton.textContent = lang === "nl" ? "Shop in 1 klik" : "Achetez en 1 clic";
    buyButton.addEventListener("click", () => {
      this.buyProducts(this.products);
      this.updateState(wrapper, this.products);
    });
    buttonsDiv.appendChild(buyButton);
    const resetButton = document.createElement("button");
    resetButton.classList.add("o-button", "--secondary", "--remove");
    resetButton.textContent = lang === "nl" ? "verwijderen" : "retirez";
    resetButton.addEventListener("click", () => {
      this.removeProducts(this.products);
      this.updateState(wrapper, this.products);
    });
    buttonsDiv.appendChild(resetButton);
    wrapper.appendChild(buttonsDiv);

    this.remove();

    const cookie = document.cookie.includes("addVirtueleBundel");
    if (window.WCParamJS.loggedIn && cookie) {
      this.buyProducts(this.products);
      document.cookie = "addVirtueleBundel=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
    }

    this.updateState(wrapper, this.products);
  }

  removeSlick(catalogWidget) {
    const slickElement = catalogWidget.querySelector(".slick-initialized");
    if (slickElement && slickElement.slick && typeof slickElement.slick.unslick === "function") {
      slider.slick.unslick();
    } else {
      const sliderInitialiser = catalogWidget.querySelector(".c-product-slider__initialiser");
      if (sliderInitialiser) sliderInitialiser.classList.remove("c-product-slider__initialiser");
    }
  }

  transformCatalogWidget(catalogWidget) {
    const productsContainer = catalogWidget.querySelector(".l-grid");
    if (productsContainer) {
      productsContainer.classList.add("webbie-virtuele-bundel--products");
      productsContainer.classList.remove("l-grid", "--collapse");
      productsContainer.querySelectorAll(".l-grid__item:not(:last-child)").forEach((el) => {
        el.insertAdjacentHTML("afterend", '<div class="webbie-virtuele-bundel--plus"><span>+</span></div>');
      });
    }
    const productNodes = [];
    if (productsContainer) {
      productsContainer.querySelectorAll(".l-grid__item").forEach((product) => {
        product.removeAttribute("class");
        product.classList.add("webbie-virtuele-bundel--product");
        productNodes.push(product);
      });
    }
    return productNodes;
  }

  buyProducts(products) {
    if (window.WCParamJS.loggedIn) {
      products.forEach((product, index) => {
        if (this.config.specificatie && this.config.specificatie.length === products.length) {
          const [unit, quantity] = this.config.specificatie[index];
          const quantityInput = product.querySelector(".add-to-basket__item input");
          if (!quantityInput) return;
          if (Number(quantityInput.value) >= quantity) return;
          const addToBasketIcon = product.querySelector(`.add-to-basket__icon.--add.--${unit}`);
          if (addToBasketIcon && quantity !== "0") {
            addToBasketIcon.click();
          } else {
            console.error(`No add-to-basket button for '${unit}' on product tile ${index + 1}`);
          }
          if (quantityInput) {
            quantityInput.value = quantity;
            quantityInput.dispatchEvent(new Event("focusout"));
            if (!Number.isInteger(quantity) && (unit === "piece" || unit === "pack")) {
              console.error(`Invalid quantity '${quantity}' for '${unit}' on product tile ${index + 1}`);
              quantityInput.value = Math.floor(quantity);
            }
          }
        } else {
          const addToBasketIcon = product.querySelector(".add-to-basket__icon.--add");
          if (addToBasketIcon) addToBasketIcon.click();
        }
      });
    } else {
      if (products[0]) products[0].querySelector(".add-to-basket__item input").dispatchEvent(new Event("blur"));
      document.cookie = "addVirtueleBundel=true; path=/";
    }
  }

  removeProducts(products) {
    products.forEach((product, index) => {
      const quantityInput = product.querySelector(".add-to-basket__item input");
      if (!quantityInput) return;
      const required = this.config.specificatie[index][1];
      if (Number(quantityInput.value) === required) {
        quantityInput.value = "0";
      } else if (Number(quantityInput.value) > required) {
        quantityInput.value = Number(quantityInput.value) - required;
      } else {
        quantityInput.value = "0";
      }
      quantityInput.dispatchEvent(new Event("blur"));
    });
  }

  async updateState(parent, products) {
    await new Promise((resolve) => setTimeout(resolve, 100));
    let bundleAddedToCart = true;
    for (let index = 0; index < products.length; index++) {
      const quantityInput = products[index].querySelector(".add-to-basket__item input");
      const requiredQuantity = this.config.specificatie[index][1];
      if (Number(quantityInput.value) < requiredQuantity) {
        bundleAddedToCart = false;
        break;
      }
    }
    if (bundleAddedToCart) parent.classList.add("--added");
    else parent.classList.remove("--added");
  }
}

customElements.define("virtual-bundle", VirtualBundle);
