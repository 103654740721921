/*
 * bodyclasses.js
 * Convenience CSS body classes
 * body.authenticated = when customer is logged in
 * body.page-specific = for targeting specific pages
 */

function pageLocation() {
  const { pathname, hash } = window.location;
  const match = pathname.match(/\/(nl|fr)\/(.+)/);
  if (match) {
    let className = "page-" + match[2].replace(/\/$/, "").replace(/\/|\?/g, "-");
    if (hash) {
      className += hash.replace("#", "-");
    }
    className = className.toLowerCase();
    className = className.replace(/cogo-/g, "collectandgo-"); // prevent global CSS from selecting body and overriding font-family with icons font
    document.body.className = document.body.className.replace(/\bpage-\S+/g, "").trim();
    document.body.classList.add(className);
  }
}
document.addEventListener("DOMContentLoaded", function () {
  pageLocation();
  window.addEventListener("popstate", pageLocation);
  window.addEventListener("hashchange", pageLocation);
  const vueAppElement = document.querySelector("page-content[data-v-app]");
  if (vueAppElement && vueAppElement.__vue_app__) {
    const router = vueAppElement.__vue_app__.config.globalProperties.$router;
    if (router?.afterEach) {
      router.afterEach(pageLocation);
    }
  }
});

function authenticated() {
  window.WCParamJS.loggedIn && document.body.classList.add("authenticated");
}
document.addEventListener("DOMContentLoaded", authenticated);

// function lockerRegion(XTRA) {
//   const gentRegionPostcodes = [9000, 9030, 9031, 9032, 9040, 9041, 9042, 9050, 9051, 9052, 9070, 9090, 9230, 9820]; // remove 1070
//   const userZip = Number(XTRA?.user?.zip?.());
//   if (userZip && gentRegionPostcodes.includes(userZip)) {
//     document.body.classList.add("locker-region");
//   }
// }

async function homeDeliveryNewlyAvailable() {
  if (!WCParamJS.loggedIn) return;
  const XTRA = await window.xtraPromise;
  const userZip = XTRA?.user?.zip?.();
  if (!userZip) {
    console.error("homeDeliveryNewlyAvailable: No user zip code found", userZip);
    return;
  }
  const codes = ["8790", "9870", "8720", "9770", "8792", "8793", "9771", "9310", "9308", "9880", "9910", "8755", "9881", "9800", "9810", "9200", "9900", "9990", "9950", "9991", "9968", "9971", "9980", "9970", "9988", "9982", "9931", "9992", "9140", "9340", "9260", "9230", "1770", "9470", "1760", "9472", "1761", "1785", "1745", "9820", "9090", "9450", "9473", "9451", "9400", "9506", "9402", "9406", "9404", "9401", "9403", "9700", "9790", "9772", "9600", "9690", "7750", "7912", "9681", "9170", "9660", "9667", "9688", "9661", "9500", "7860", "9570", "7864", "9572", "7863", "9571", "9620", "9550", "9520", "9552", "9551", "9521"];
  if (userZip && codes.includes(userZip)) {
    document.body.classList.add("home-delivery-newly-available");
  }
}
document.addEventListener("DOMContentLoaded", homeDeliveryNewlyAvailable);
