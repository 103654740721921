/*
 * extra-nav-links.js
 * Add some extra links to destkop top navigation like "Levering aan huis" and "Afhaalpunt in jouw buurt"
 */

document.addEventListener("DOMContentLoaded", function () {
  const lang = window.WCParamJS.langId === "-1001" ? "nl" : "fr";

  const desktopNL = `
  <div class="extraNavLinks-desktop">
    <ul>
      <li>
        <a data-tms-intpromo-name="header_link-AHL" href="/colruyt/nl/levering-aan-huis">
          <svg class="home-delivery-newly-available-badge" viewBox="0 0 94 12" style="width: 94px; height: auto">
            <rect width="100%" height="100%" rx="6" fill="#82e0ae"></rect>
            <text x="50%" y="50%" text-anchor="middle" dominant-baseline="central" fill="#1c3661" class="tk-mic-32-new-rounded-web" font-weight="bold" font-style="italic" font-size="10">nieuw in jouw regio</text>
          </svg>
          <span>Levering aan huis</span>
        </a>
      </li>
      <li>
        <a data-tms-intpromo-name="header_link-afhaalpunt" href="/colruyt/nl/faq-vind-afhaalpunt">
          <span>Afhaalpunt in jouw buurt</span>
        </a>
      </li>
    </ul>
  </div>
  `;
  const desktopFR = `
  <div class="extraNavLinks-desktop">
    <ul>
      <li>
        <a data-tms-intpromo-name="header_link-afhaalpunt" href="/colruyt/fr/livraison-a-domicile">
          <svg class="home-delivery-newly-available-badge" viewBox="0 0 125 12" style="width: 125px; height: auto">
              <rect width="100%" height="100%" rx="6" fill="#82e0ae"></rect>
              <text x="50%" y="50%" text-anchor="middle" dominant-baseline="central" fill="#1c3661" class="tk-mic-32-new-rounded-web" font-weight="bold" font-style="italic" font-size="10">nouveau dans votre r&eacute;gion</text>
          </svg>
          <span>Livraison &agrave; domicile</span>
        </a>
      </li>
      <li>
        <a data-tms-intpromo-name="header_link-AHL" href="/colruyt/fr/faq-trouver-point-enlevement">
          <span>Trouvez un point d'enl&egrave;vement</span>
        </a>
      </li>
    </ul>
  </div>
  `;

  const headerRight = document.querySelector(".c-header__top .c-header__right");
  if (headerRight) {
    headerRight.insertAdjacentHTML("afterbegin", lang === "nl" ? desktopNL : desktopFR);
  }
});
