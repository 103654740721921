/*
 * chat.js
 * Load Salesforce chat component scripts
 */

const chatLang = WCParamJS.langId === "-2" ? "fr" : "nl_NL";
const isSystChat = new URLSearchParams(window.location.search).has("systChat");

const chatConfigLive = {
  orgId: "00D09000007yiDp",
  esConfigName: "COGO_Chat",
  siteUrl: "https://colruytgroup2.my.site.com/ESWCOGOChat1726637540041",
  embeddedServiceConfig: {
    scrt2URL: "https://colruytgroup2.my.salesforce-scrt.com",
  },
};

const chatConfigSyst = {
  orgId: "00D250000009WKa",
  esConfigName: "Collect_and_Go_Chat",
  siteUrl: "https://colruytgroup2--syst.sandbox.my.site.com/ESWCollectandGoChat1724827190270",
  embeddedServiceConfig: {
    scrt2URL: "https://colruytgroup2--syst.sandbox.my.salesforce-scrt.com",
  },
};

const chatConfig = isSystChat ? chatConfigSyst : chatConfigLive;

const initChat = () => {
  const bootstrap = window.embeddedservice_bootstrap;
  if (!bootstrap?.init) return;

  try {
    bootstrap.settings.language = chatLang;
    bootstrap.init(chatConfig.orgId, chatConfig.esConfigName, chatConfig.siteUrl, chatConfig.embeddedServiceConfig);
  } catch (err) {
    console.error("Error loading Embedded Messaging:", err);
  }
};

const script = document.createElement("script");
script.src = `${chatConfig.siteUrl}/assets/js/bootstrap.min.js`;
script.onload = initChat;
document.head.appendChild(script);
const style = document.createElement("style");
style.textContent = `
    #embedded-messaging > * { z-index: 1020 }
    .back-to-top { z-index: 1010 !important; right: 30px !important; width: 56px !important; height: 56px !important; bottom: 90px !important; }
  `;
document.head.appendChild(style);

const setupPrechat = async () => {
  const { embeddedservice_bootstrap: bootstrap, xtraPromise } = window;
  if (!bootstrap?.prechatAPI) return;

  bootstrap.prechatAPI.setHiddenPrechatFields({ Language: chatLang });

  if (!WCParamJS.loggedIn) return;
  const firstName = (await xtraPromise)?.user?.firstName();
  if (firstName) {
    bootstrap.prechatAPI.setVisiblePrechatFields({
      _firstName: { value: firstName, isEditableByEndUser: false },
    });
  }
};

window.addEventListener("onEmbeddedMessagingReady", setupPrechat);

export {};

// function handleTimeBasedLogic(config) {
//   const { orgId, esConfigName, embeddedServiceConfig } = config.init;
//   const scrt2URL = embeddedServiceConfig.scrt2URL;
//   fetch(`${scrt2URL}/embeddedservice/v1/businesshours?orgId=${orgId}&esConfigName=${esConfigName}`)
//     .then((response) => response.json())
//     .then((data) => {
//       const businessHours = data.businessHoursInfo?.businessHours;
//       if (!Array.isArray(businessHours) || businessHours.length === 0) {
//         return;
//       }
//       function isWithinBusinessHours() {
//         const now = Date.now();
//         return businessHours.some((hourRange) => {
//           return now >= hourRange.startTime && now < hourRange.endTime;
//         });
//       }
//       function updateTime() {
//         const isActive = isWithinBusinessHours();
//         document.body.classList.toggle("chat-inactive", !isActive);
//       }
//       setInterval(updateTime, 1000);
//     });
// }

// function addDisabledButton() {
//   function addDisabledButtonStyles() {
//     const style = document.createElement("style");
//     style.textContent = `
//       .disabled-chat-button {
//         position: fixed;
//         z-index: 1015;
//         bottom: 25px;
//         right: 30px;
//         width: 56px;
//         height: 56px;
//         background-color: var(--universal-action-300);
//         border-radius: 28px;
//         display: none;
//         align-items: center;
//         justify-content: center;
//       }
//       .chat-inactive .disabled-chat-button { display: flex }
//       .disabled-chat-button svg {
//         width: 30px;
//         height: 30px;
//         fill: var(--universal-action-200);
//       }
//     `;
//     document.head.appendChild(style);
//   }
//   addDisabledButtonStyles();
//   const title_nl = "Chat met ons van ma. - zat. 10 tot 19 u";
//   const title_fr = "Chattez avec nous du lun. au sam. de 10 h à 19 h";
//   const link_nl = "/nl/contact";
//   const link_fr = "/fr/contact";
//   const isfrench = window.WCParamJS?.langId === "-2";
//   const title = isfrench ? title_fr : title_nl;
//   const href = isfrench ? link_fr : link_nl;
//   const buttonHtml = `
//     <a title="${title}" tabindex="0" href="${href}" class="disabled-chat-button">
//       <svg focusable="false" aria-hidden="true" viewBox="0 0 100 100"><path d="M50 0c27.614 0 50 20.52 50 45.833S77.614 91.667 50 91.667c-8.458 0-16.425-1.925-23.409-5.323-13.33 6.973-21.083 9.839-23.258 8.595-2.064-1.18.114-8.436 6.534-21.767C3.667 65.54 0 56.08 0 45.833 0 20.52 22.386 0 50 0zm4.583 61.667H22.917a2.917 2.917 0 000 5.833h31.666a2.917 2.917 0 000-5.833zm12.5-15.834H22.917a2.917 2.917 0 000 5.834h44.166a2.917 2.917 0 000-5.834zM79.583 30H22.917a2.917 2.917 0 000 5.833h56.666a2.917 2.917 0 000-5.833z"></path></svg>
//     </a>
//   `;

//   const buttonContainer = document.createElement("div");
//   buttonContainer.id = "disabled-chat-button-container";
//   buttonContainer.innerHTML = buttonHtml;
//   document.body.appendChild(buttonContainer);
// }
