/**
 * @class MarketingBanner
 * <marketing-banner ...></marketing-banner>
 */

class MarketingBanner extends HTMLElement {
  constructor() {
    super();
    this._initialized = false;
    this.attachShadow({ mode: "open" });
    this._root = null;
    const link = document.createElement("link");
    link.setAttribute("rel", "stylesheet");
    link.setAttribute(
      "href",
      "/cogoecomwcs/static/efood/css/marketing-banner-20250325.css"
    );
    this.shadowRoot.append(link);
    this.dataset.tmsIntpromoClick = "true";
  }
  loadThemeFont(theme) {
    if (!theme) return;
    const THEME_FONTS = {
      clp: "/cogoecomwcs/static/efood/fonts/otfdynamite-medium-webfont.css",
      bio: "https://fonts.googleapis.com/css2?family=Montserrat:wght@500",
    };
    const fontRef = THEME_FONTS[theme];
    if (!fontRef || document.querySelector(`link[href="${fontRef}"]`)) return;
    try {
      const font = document.createElement("link");
      font.setAttribute("rel", "stylesheet");
      font.setAttribute("href", fontRef);
      document.head.append(font);
    } catch {}
  }
  async loadTodaysRecipe() {
    let currentDate;
    if (window.absoluteURL === "https://www.collectandgo.be/") {
      currentDate = new Date(parseInt(WCParamJS.analytics.env_server_time, 10));
    } else {
      // get staging preview date
      const res = await fetch("/webapp/wcs/stores/servlet/ColPreviewDateSet");
      if (res.ok) {
        let raw = await res.text();
        let data = JSON.parse(raw.replace("/*", "").replace("*/", ""));
        currentDate = new Date(data.time);
      }
    }
    if (!currentDate) return;
    const firstDay = new Date(currentDate.getFullYear(), 0, 1);
    const week = Math.ceil(((currentDate - firstDay) / 86400000 + 1) / 7);
    let day = currentDate.getDay();
    day = day === 0 ? 7 : day;
    const langId = WCParamJS.langId;
    const days =
      langId === "-1001"
        ? [
            "Maandag",
            "Dinsdag",
            "Woensdag",
            "Donderdag",
            "Vrijdag",
            "Zaterdag",
            "Zondag",
          ]
        : [
            "Lundi",
            "Mardi",
            "Mercredi",
            "Jeudi",
            "Vendredi",
            "Samedi",
            "Dimanche",
          ];
    const spotRes = await fetch(
      "/AjaxEMarketingSpot?emsName=RECIPE_WEEKLY_MENU_CLP"
    );
    if (!spotRes.ok) return;
    const spotHtml = await spotRes.text();
    const parser = new DOMParser();
    const doc = parser.parseFromString(spotHtml, "text/html");
    const recipeItems = Array.from(doc.querySelectorAll("recipe-item"));
    let recipeId = null;
    for (let i = day - 1; i >= 0; i--) {
      const item = recipeItems.find(
        (el) =>
          el.getAttribute("data-week") == week &&
          el.getAttribute("data-day").toLowerCase() === days[i].toLowerCase()
      );
      if (item) {
        recipeId = item.getAttribute("data-id");
        break;
      }
    }
    if (!recipeId) return;
    const detailsFetchURL = `${WCParamJS.recipeGatewayUrl}/store/${WCParamJS.storeId}/recipes/details/recipeIds/${recipeId}?langId=${langId}`;
    let detailsRes;
    try {
      detailsRes = await fetch(detailsFetchURL);
    } catch (error) {
      console.error("Failed to fetch recipe details:", error);
      return;
    }

    if (!detailsRes.ok) return;
    const details = await detailsRes.json();

    const recipe = details.recipeProfile.recipeList[0];
    if (!recipe) return;
    const recipeName = recipe.name[0].name;
    const recipeDescription = recipe.description[0].name;
    const imageUrl = recipe.recipeHasImages[0]?.media?.url;
    if (!recipeName || !recipeDescription || !imageUrl) return;
    const kebabName = recipeName
      .toLowerCase()
      .replace(/[^\w\s-]/g, "")
      .trim()
      .replace(/\s+/g, "-");
    const basePath = langId === "-1001" ? "recepten" : "recettes";
    const href = `${basePath}/${kebabName}?recipeId=${recipeId}`;
    this.setAttribute("recipe-name", recipeName);
    this.setAttribute("background-image", `url(${imageUrl})`);
    this.setAttribute("href", href);
    this.setAttribute("title", recipeName + " - " + recipeDescription);
  }
  renderBanner() {
    this._root = document.createElement("a");
    const theme = this.getAttribute("theme");
    if (theme) {
      this.loadThemeFont(theme);
      this._root.classList.add(theme);
    }
    this._root.href = this.getAttribute("href");

    const url = new URL(this._root.href, window.location.origin);
    if (
      url.hostname !== "www.collectandgo.be" &&
      !url.hostname.includes("colruyt.int")
    ) {
      this._root.target = "_blank";
    }

    this._root.style.backgroundImage = this.getAttribute("background-image");
    this._root.style.backgroundColor = this.getAttribute("background-color");

    const borderColor = this.getAttribute("border-color");
    if (borderColor) {
      this._root.style.border = `4px solid ${borderColor}`;
    }

    if (this.hasAttribute("gradient")) {
      const gradientElement = document.createElement("div");
      gradientElement.classList.add("gradient");
      this._root.append(gradientElement);
    }
    this._buttonElement = document.createElement("div");
    this._buttonElement.slot = "button";
    this.updateButton();
    if (this.hasAttribute("button")) {
      this._root.classList.add("has-button");
      this._root.append(this._buttonElement);
    }

    const heading = this.getAttribute("heading");
    const headingElement = document.createElement("h2");
    headingElement.classList.add("heading");
    if (heading) {
      headingElement.innerHTML = heading;
      this._root.append(headingElement);
    }

    const recipeName = this.getAttribute("recipe-name");
    if (recipeName) {
      const recipeNameElement = document.createElement("span");
      recipeNameElement.textContent = recipeName;
      recipeNameElement.className = "recipe-name";
      headingElement.append(recipeNameElement);
    }

    if (theme === "clp") {
      if (this.hasAttribute("toppromo")) {
        this._root.classList.add("topPromo");
        const topPromoImage = document.createElement("img");
        topPromoImage.classList.add("topPromo-logo");
        topPromoImage.src =
          "/cogoecomwcs/static/efood/css/assets/logo-toppromo.png";
        this._root.append(topPromoImage);
      }
      const logo = document.createElement("img");
      logo.classList.add("clp-logo");
      logo.src = `${window.WCParamJS.staticAssetContextRoot}/static/build/images/logo-clp.svg`;
      headingElement.prepend(logo);
      this._root.append(headingElement);
    }

    this._root.addEventListener("click", (event) => {
      this.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          composed: true,
        })
      );
    });

    this.shadowRoot.append(this._root);
  }
  connectedCallback() {
    if (this._initialized) return;
    this._initialized = true;
    if (this.getAttribute("content") === "todays-recipe") {
      this.loadTodaysRecipe().then(() => {
        this.renderBanner();
      });
    } else {
      this.renderBanner();
    }
  }

  static get observedAttributes() {
    return ["button"];
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (!this._initialized) return;
    if (name === "button") this.updateButton();
  }

  updateButton() {
    const val = this.getAttribute("button");
    if (!this._buttonElement || !this._root) return;
    const hasButton = val !== null;
    this._root.classList.toggle("has-button", hasButton);
    this._buttonElement.className = val
      ? "cogo-button-with-label"
      : "cogo-button";
    this._buttonElement.textContent = val || "";
    if (hasButton && !this._buttonElement.isConnected) {
      this._root.append(this._buttonElement);
    }
  }
}
customElements.define("marketing-banner", MarketingBanner);
